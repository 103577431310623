// Note: importing this file will publish Blacklight and jQuery/$ to the global scope,
//   but, for the sake of future-proofing, let's explicitly import them in JS under our control.

// Anything that needs to happen before Blacklight is imported (e.g. Turbolinks.start())
//   needs to happen in an import instead of just inline in this file.
import './global_dependencies'

// All the Blacklight modules as of 7.33.1 are listed below.
// The ones we definitely don't need are commented out.
// I've erred on the side of including when unsure.
import 'blacklight-frontend/app/javascript/blacklight/core' // this one sets window.Blacklight
import 'blacklight-frontend/app/javascript/blacklight/modal'
import 'blacklight-frontend/app/javascript/blacklight/search_context'
import 'blacklight-frontend/app/javascript/blacklight/facet_load'
import 'blacklight-frontend/app/javascript/blacklight/button_focus'
//import 'blacklight-frontend/app/javascript/blacklight/autocomplete'
//import 'blacklight-frontend/app/javascript/blacklight/checkbox_submit'
//import 'blacklight-frontend/app/javascript/blacklight/bookmark_toggle'

const Blacklight = window.Blacklight;

// Blacklight range limit
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.canvaswrapper.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.colorhelpers.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.flot.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.flot.browser.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.flot.saturated.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.flot.drawSeries.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.event.drag.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.flot.hover.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.flot.uiConstants.js'
import 'blacklight-range-limit/vendor/assets/javascripts/flot/jquery.flot.selection.js'
import 'blacklight-range-limit/vendor/assets/javascripts/bootstrap-slider'
import BlacklightRangeLimit from 'blacklight-range-limit/app/assets/javascripts/blacklight_range_limit/blacklight_range_limit.esm'

Blacklight.onLoad(function () {
    if (document.querySelector(Blacklight.modal.modalSelector)) BlacklightRangeLimit.initialize(Blacklight.modal.modalSelector);
});

export { Blacklight as default };
